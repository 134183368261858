// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.review-slide {
  padding: 20px;
}

.review-slide p {
  font-size: 1.2rem;
  color: #555;
  font-style: italic;
}

.review-slide h4 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.stars {
  margin-top: 10px;
}

.stars span {
  font-size: 1.5rem;
}

.review-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0f8bf0;
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.review-link:hover {
  background-color: #FFA500;
}
`, "",{"version":3,"sources":["webpack://./src/FrontEnd/Testimonials/Testimonial.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,aAAa;EACb,+BAA+B;EAC/B,kBAAkB;EAClB,+CAA+C;EAC/C,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".slider-container {\n  width: 80%;\n  margin: 0 auto;\n  padding: 20px;\n  /* background-color: #f9f9f9; */\n  border-radius: 8px;\n  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */\n  text-align: center;\n}\n\nh1 {\n  font-family: Arial, Helvetica, sans-serif;\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 20px;\n}\n\n.review-slide {\n  padding: 20px;\n}\n\n.review-slide p {\n  font-size: 1.2rem;\n  color: #555;\n  font-style: italic;\n}\n\n.review-slide h4 {\n  font-size: 1.1rem;\n  font-weight: bold;\n  color: #333;\n  margin-top: 10px;\n}\n\n.stars {\n  margin-top: 10px;\n}\n\n.stars span {\n  font-size: 1.5rem;\n}\n\n.review-link {\n  display: inline-block;\n  margin-top: 20px;\n  padding: 10px 20px;\n  background-color: #0f8bf0;\n  color: #fff;\n  text-decoration: none;\n  font-size: 1.1rem;\n  border-radius: 4px;\n  transition: background-color 0.3s ease;\n}\n\n.review-link:hover {\n  background-color: #FFA500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
