import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import reviews from './testimonials.json'; // Import your JSON data

const StarRating = ({ rating }) => {
  return (
    <div className="stars">
      {Array.from({ length: 5 }, (_, index) => (
        <span key={index} style={{ color: index < rating ? "#FFD700" : "#CCC" }}>
          ★
        </span>
      ))}
    </div>
  );
};

const Testimonial = () => {
  const settings = {
    dots: false, // Set to false to remove the dots
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Default number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide arrows
    responsive: [
      {
        breakpoint: 1024, // Tablets
        settings: {
          slidesToShow: 2, // Show 2 slides on tablets
          slidesToScroll: 2,
          infinite: true,
          dots: false, // Disable dots on tablets as well
        },
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          slidesToScroll: 1,
          infinite: true,
          dots: false, // Disable dots on mobile as well
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <h1> Google Reviews </h1>
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="review-slide">
            <p>"{review.review}"</p>
            <h4>{review.name}</h4>
            <StarRating rating={review.rating} />
            <small>{review.date}</small>
          </div>
        ))}
      </Slider>
      {/* Add a link for submitting a new review or viewing more reviews */}
      <a 
        href="https://g.page/r/CW-j3RK-e_6FEAE/review" 
        target="_blank" 
        rel="noopener noreferrer"
        className="review-link"
      >
        Leave a Review
      </a>
    </div>
  );
};

export default Testimonial;
